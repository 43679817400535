input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(19%) sepia(19%) saturate(3185%) hue-rotate(149deg) brightness(96%) contrast(95%);}
:root{--rt-color-white:#fff;--rt-color-dark:#222;--rt-color-success:#8dc572;--rt-color-error:#be6464;--rt-color-warning:#f0ad4e;--rt-color-info:#337ab7;--rt-opacity:0.9}.styles-module_tooltip__mnnfp{border-radius:3px;font-size:90%;left:0;opacity:0;padding:8px 16px;pointer-events:none;position:absolute;top:0;transition:opacity .3s ease-out;visibility:hidden;width:-webkit-max-content;width:max-content;will-change:opacity,visibility}.styles-module_fixed__7ciUi{position:fixed}.styles-module_arrow__K0L3T{background:inherit;height:8px;position:absolute;transform:rotate(45deg);width:8px}.styles-module_noArrow__T8y2L{display:none}.styles-module_clickable__Bv9o7{pointer-events:auto}.styles-module_show__2NboJ{opacity:var(--rt-opacity);visibility:visible}.styles-module_dark__xNqje{background:var(--rt-color-dark);color:var(--rt-color-white)}.styles-module_light__Z6W-X{background-color:var(--rt-color-white);color:var(--rt-color-dark)}.styles-module_success__A2AKt{background-color:var(--rt-color-success);color:var(--rt-color-white)}.styles-module_warning__SCK0X{background-color:var(--rt-color-warning);color:var(--rt-color-white)}.styles-module_error__JvumD{background-color:var(--rt-color-error);color:var(--rt-color-white)}.styles-module_info__BWdHW{background-color:var(--rt-color-info);color:var(--rt-color-white)}._textarea_jkz6q_1 {

    resize: none
}

._textarea_jkz6q_1::-webkit-scrollbar {

    width: 0.5rem;

    border-radius: 0.25rem;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

._textarea_jkz6q_1::-webkit-scrollbar-track {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

._textarea_jkz6q_1::-webkit-scrollbar-thumb {

    border-radius: 0.25rem;

    --tw-bg-opacity: 1;

    background-color: rgb(173 177 178 / var(--tw-bg-opacity));

    outline-color: #ADB1B2
}

._textarea_jkz6q_1::-moz-placeholder {

    --tw-text-opacity: 1;

    color: rgb(152 156 157 / var(--tw-text-opacity))
}

._textarea_jkz6q_1::placeholder {

    --tw-text-opacity: 1;

    color: rgb(152 156 157 / var(--tw-text-opacity))
}
